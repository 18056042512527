import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { Area } from '../../@types/area';
import axios from '../../utils/axios';
import { actions } from './area.slice';

function* fetchDetails(action: PayloadAction<{ id: number }>) {
  try {
    yield put(actions.startLoading());
    const response: { data: Area } = yield call(axios.get, `/v1/areas/${action.payload.id}`);
    yield put(actions.setData(response.data));
  } catch (error) {
    yield put(actions.setError(error));
  }
}

export default function* areaSaga() {
  yield takeEvery(actions.loadData.type, fetchDetails);
}
