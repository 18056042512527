import Iconify from 'src/components/Iconify';

const navConfig = [
  {
    // subheader: 'navigation.general',
    items: [
      {
        title: 'navigation.dashboard',
        path: '/dashboard',
        icon: <Iconify icon={'ic:round-dashboard'} />,
      },
    ],
  },
  {
    subheader: 'Preview',
    items: [
      {
        title: 'Matrix',
        path: '/preview/matrix',
        icon: <Iconify icon={'fa-solid:layer-group'} />,
      },
      {
        title: 'Resources',
        path: '/preview/resources',
        icon: <Iconify icon={'mdi:bookmark-multiple'} />,
      },
      {
        title: 'Content',
        path: '/preview/content',
        icon: <Iconify icon={'fa-solid:book'} />,
      },
    ],
  },
  {
    subheader: 'Edit',
    items: [
      {
        title: 'Competencies',
        path: '/tree/areas',
        icon: <Iconify icon={'fa-solid:balance-scale'} />,
      },
      {
        title: 'Areas',
        path: '/areas',
        icon: <Iconify icon={'fa-solid:atlas'} />,
      },
      {
        title: 'Positions',
        path: '/positions',
        icon: <Iconify icon={'fa-solid:user-graduate'} />,
      },
    ],
  },
  {
    subheader: 'navigation.settings',
    items: [
      {
        title: 'navigation.users',
        path: 'users',
        icon: <Iconify icon={'mdi:accounts'} />,
        children: [
          { title: 'navigation.users', path: '/users' },
          { title: 'navigation.permissions', path: '/users/permissions' },
        ],
      },
      {
        title: 'navigation.settings',
        path: '/settings',
        icon: <Iconify icon={'mdi:settings'} />,
      },
    ],
  },

  // {
  //   subheader: 'navigation.content',
  //   items: [
  //     { title: 'navigation.pages', path: '/pages', icon: ICONS.ecommerce },
  //     {
  //       title: 'navigation.posts',
  //       path: '/posts',
  //       icon: ICONS.analytics,
  //       children: [
  //         { title: 'navigation.posts', path: '/posts' },
  //         { title: 'navigation.postCategories', path: '/posts/categories' },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
