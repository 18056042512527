import { createSlice, DeepPartial, Draft } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { merge } from 'lodash';

export type TableFilters<F extends Record<string, any> = {}> = { search: string } & F;

export type TableSliceState<T, F extends Record<string, any> = {}> = {
  isLoading: boolean;
  error: Error | string | null;
  data: T[];
  total: number;
  selected: string[];
  page: number;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  tab: string | null;
  filters: TableFilters<F>;
};

const DEFAULT_STATE = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  selected: [],
  page: 0,
  rowsPerPage: 10,
  order: 'asc',
  orderBy: 'id',
  tab: 'all',
  filters: {
    search: '',
  },
};

interface CreateSliceParams<T, F extends Record<string, any> = {}> {
  name: string;
  initialState?: DeepPartial<TableSliceState<T, F>>;
  // reducers?: any;
  // extraReducers?: any;
}

function createTableSlice<T, F extends Record<string, any> = {}>({
  name,
  initialState: state,
}: // reducers,
// extraReducers,
CreateSliceParams<T, F>) {
  const initialState = merge({}, DEFAULT_STATE, state) as TableSliceState<T, F>;

  return createSlice({
    name,
    initialState,
    reducers: {
      reset: () => ({ ...initialState }),

      startLoading(state) {
        state.isLoading = true;
      },

      setError(state, action: PayloadAction<Error | string | null>) {
        state.isLoading = false;
        state.error = action.payload;
      },

      setData(state, action: PayloadAction<{ data: Draft<T>[]; total: number }>) {
        // setData(state, action) {
        state.isLoading = false;
        state.data = action.payload.data;
        state.total = action.payload.total;
      },

      setTab(state, action: PayloadAction<string>) {
        state.tab = action.payload;
      },

      // setFilter(state, action: PayloadAction<{ name: keyof Draft<TableFilters<F>>; value: any }>) {
      //   state.filters[action.payload.name] = action.payload.value;
      // },

      loadData(state, action: PayloadAction<DeepPartial<TableFilters<F>>>) {
        state.page = 0;
        state.filters = merge({}, state.filters, action.payload);
      },

      setFilters(state, action: PayloadAction<DeepPartial<TableFilters<F>>>) {
        state.page = 0;
        state.filters = merge({}, state.filters, action.payload);
      },

      setPage(state, action: PayloadAction<number>) {
        state.page = action.payload;
      },

      setRowsPerPage(state, action: PayloadAction<number>) {
        state.page = 0;
        state.rowsPerPage = action.payload;
      },

      setSortBy(state, action: PayloadAction<string>) {
        const key = action.payload;
        const isAsc = state.orderBy === key && state.order === 'asc';
        if (key !== '') {
          state.order = isAsc ? 'desc' : 'asc';
          state.orderBy = key;
        }
      },

      selectRow(state, action: PayloadAction<string>) {
        // const id = action.payload;
        //   const selectedIndex = selected.indexOf(id);
        //
        //   let newSelected: string[] = [];
        //
        //   if (selectedIndex === -1) {
        //     newSelected = newSelected.concat(selected, id);
        //   } else if (selectedIndex === 0) {
        //     newSelected = newSelected.concat(selected.slice(1));
        //   } else if (selectedIndex === selected.length - 1) {
        //     newSelected = newSelected.concat(selected.slice(0, -1));
        //   } else if (selectedIndex > 0) {
        //     newSelected = newSelected.concat(
        //       selected.slice(0, selectedIndex),
        //       selected.slice(selectedIndex + 1)
        //     );
        //   }
        //   setSelected(newSelected);
      },

      selectAllRows(state, action: PayloadAction<string[]>) {
        // const newSelecteds = action.payload;
        // if (checked) {
        //   setSelected(newSelecteds);
        //   return;
        // }
        // setSelected([]);
      },

      // ...reducers,
    },
    // extraReducers: {
    //   ...extraReducers,
    // },
  });
}

export type CreateTableSlice<T, F extends Record<string, any> = {}> = ReturnType<
  typeof createTableSlice<T, F>
>;

export default createTableSlice;
