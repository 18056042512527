import { User } from '../../@types/user';
import createDetailsSlice, { DetailsSliceState } from '../utils/createDetailsSlice';

export const userSlice = createDetailsSlice<User>({
  name: 'user',
  initialState: {},
});

export type UserDetailsSlice = DetailsSliceState<User>;

export const { actions, reducer } = userSlice;

export default userSlice;
