import { Position } from '../../@types/position';
import createDetailsSlice, { DetailsSliceState } from '../utils/createDetailsSlice';

export const positionSlice = createDetailsSlice<Position>({
  name: 'position',
  initialState: {},
});

export type PositionDetailsSlice = DetailsSliceState<Position>;

export const { actions, reducer } = positionSlice;

export default positionSlice;
