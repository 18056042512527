import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { kebabCase } from 'lodash';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Iconify from '../Iconify';

type IProps = {
  name: string;
  contentField: string;
};

type Props = IProps & TextFieldProps;

export default function RHFSlugField({ name, contentField, ...other }: Props) {
  const { control, setValue, watch } = useFormContext();

  const content = watch(contentField);

  const setSlugValue = useCallback(() => {
    setValue(name, kebabCase(content));
  }, [setValue, name, content]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <TextField
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={setSlugValue}>
                  <Iconify icon={'mingcute:copy-3-line'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...other}
        />
      )}
    />
  );
}
