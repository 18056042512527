import { all } from 'redux-saga/effects';

import areaSaga from './area/area.saga';
import areasSaga from './area/areas.saga';
import positionSaga from './position/position.saga';
import positionsSaga from './position/positions.saga';
import userSaga from './user/user.saga';
import usersSaga from './user/users.saga';

export default function* rootSaga() {
  yield all([usersSaga(), userSaga(), positionsSaga(), positionSaga(), areasSaga(), areaSaga()]);
}
