import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { Position } from '../../@types/position';
import axios from '../../utils/axios';
import { actions } from './position.slice';

function* fetchPosition(action: PayloadAction<{ id: number }>) {
  try {
    yield put(actions.startLoading());
    const response: { data: Position } = yield call(
      axios.get,
      `/v1/positions/${action.payload.id}`
    );
    yield put(actions.setData(response.data));
  } catch (error) {
    yield put(actions.setError(error));
  }
}

export default function* positionSaga() {
  yield takeEvery(actions.loadData.type, fetchPosition);
}
