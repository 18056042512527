const uk = {
  common: {
    settings: 'Налаштування',
    version: 'Версія {{version}}',
  },
  navigation: {
    general: 'Загальні',
    dashboard: 'Панель Керування',
    content: 'Контент',
    pages: 'Сторінки',
    posts: 'Статті',
    postCategories: 'Категорії',
    accounts: 'Акаунти',
    users: 'Користувачі',
    permissions: 'Дозволи',
    settings: 'Налаштування',
  },
  settings: {
    mode: 'Кольоровий Режим',
    presets: 'Основний Колір',
    fullscreen: 'Повноекранний Режим',
    exitFullscreen: 'Вийти з Повноекранного Режиму',
  },
  404: {
    title: '404 Сторінку Не Знайдено',
    header: 'Вибачте, сторінка не знайдена!',
    body: "На жаль, нам не вдалося знайти сторінку, яку ви шукаєте. Можливо, ви неправильно ввели URL? Обов'язково перевіряйте правопис.",
  },
};

export default uk;
