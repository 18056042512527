import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import useAuth from '../hooks/useAuth';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import Login from '../pages/auth/Login';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   ),
        // },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        // { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'new-password', element: <NewPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'pages', element: <ComingSoonPage /> },
        {
          path: 'positions',
          children: [
            { element: <PositionList />, index: true },
            { path: 'new', element: <PositionCreate /> },
            { path: ':id/edit', element: <PositionUpdate /> },
          ],
        },
        {
          path: 'areas',
          children: [
            { element: <AreaList />, index: true },
            { path: 'new', element: <AreaCreate /> },
            { path: ':id/edit', element: <AreaUpdate /> },
          ],
        },
        {
          path: 'tree',
          children: [{ path: 'areas', element: <AreaTree /> }],
        },
        {
          path: 'preview',
          children: [
            { path: 'resources', element: <ResourcesPreview /> },
            { path: 'content', element: <ContentPreview /> },
            { path: 'matrix', element: <MatrixPreview /> },
          ],
        },
        {
          path: 'posts',
          children: [
            // { element: <Navigate to="/posts/list" replace />, index: true },
            // { path: 'list', element: <ComingSoonPage /> },
            { element: <ComingSoonPage />, index: true },
            { path: 'categories', element: <ComingSoonPage /> },
          ],
        },
        {
          path: 'users',
          children: [
            { element: <UserList />, index: true },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserUpdate /> },
            {
              path: 'permissions',
              children: [{ element: <ComingSoonPage />, index: true }],
              element: <ComingSoonPage />,
            },
          ],
        },
        { path: 'settings', element: <PageOne /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const UserList = Loadable(lazy(() => import('../pages/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/user/UserCreate')));
const UserUpdate = Loadable(lazy(() => import('../pages/user/UserUpdate')));

const PositionList = Loadable(lazy(() => import('../pages/position/PositionList')));
const PositionCreate = Loadable(lazy(() => import('../pages/position/PositionCreate')));
const PositionUpdate = Loadable(lazy(() => import('../pages/position/PositionUpdate')));

const AreaList = Loadable(lazy(() => import('../pages/area/AreaList')));
const AreaCreate = Loadable(lazy(() => import('../pages/area/AreaCreate')));
const AreaUpdate = Loadable(lazy(() => import('../pages/area/AreaUpdate')));
const AreaTree = Loadable(lazy(() => import('../pages/tree/AreaTree')));

const ResourcesPreview = Loadable(lazy(() => import('../pages/preview/ResourcesPreview')));
const ContentPreview = Loadable(lazy(() => import('../pages/preview/ContentPreview')));
const MatrixPreview = Loadable(lazy(() => import('../pages/preview/MatrixPreview')));
