import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import { PATH_AUTH } from '../../../routes/paths';

export default function LogoutButton() {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <Button onClick={handleLogout} aria-label="logout">
      Logout <Iconify icon={'mdi:exit-to-app'} width={20} height={20} sx={{ ml: 1 }} />
    </Button>
  );
}
