import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

interface Props extends BoxProps {
  disabledLink?: boolean;
  showLabel?: boolean;
}

export default function Logo({ disabledLink = false, showLabel = false, sx }: Props) {
  const theme = useTheme();

  const PRIMARY = theme.palette.primary.main;

  const TEXT = theme.palette.text.secondary;

  // OR
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box sx={{ width: showLabel ? 200 : 40, height: 40, ...sx }}>
      <svg
        // style={{ background: 'red' }}
        width="100%"
        height="100%"
        viewBox={showLabel ? '0 0 125 16.4' : '0 0 27 16.4'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={PRIMARY}>
          {showLabel && (
            <>
              <path d="m74 5.05h-3.2v8.28h-2.65v-8.28h-3.2v-1.98h9.05z" />
              <path d="m64.2 10.1c0.0389 1.12-0.663 2.15-1.62 2.69-1.41 0.81-3.11 0.806-4.69 0.628-0.848-0.118-1.67-0.376-2.46-0.697v-2.46c0.295-0.0712 0.485 0.159 0.711 0.308 1.33 0.865 3.02 1.3 4.58 0.893 0.45-0.128 0.883-0.54 0.742-1.05-0.193-0.619-0.92-0.759-1.47-0.879-1.28-0.263-2.68-0.459-3.69-1.36-0.866-0.817-1.03-2.23-0.523-3.28 0.659-1.23 2.07-1.82 3.4-1.97 1.56-0.159 3.16 0.0834 4.62 0.639v2.36c-0.397 0.0825-0.627-0.333-0.973-0.455-1.17-0.626-2.59-0.936-3.88-0.526-0.455 0.132-0.885 0.604-0.687 1.1 0.212 0.523 0.857 0.584 1.33 0.718 1.28 0.289 2.67 0.448 3.73 1.29 0.614 0.486 0.899 1.27 0.883 2.04z" />
              <path d="m53.5 9.61c0.0274 1.2-0.426 2.48-1.46 3.15-1.2 0.789-2.71 0.853-4.1 0.72-1.23-0.126-2.53-0.689-3.12-1.84-0.576-1.04-0.429-2.25-0.449-3.39v-5.19h2.66c0.0045 2.22-0.0092 4.44 7e-3 6.66 0.0198 0.695 0.302 1.5 1.03 1.73 0.713 0.209 1.58 0.213 2.19-0.254 0.637-0.567 0.585-1.48 0.584-2.26v-5.87h2.66v6.55z" />
              <path d="m41.9 13.3h-2.63v-6.87l-1.9 4.46h-1.83l-1.9-4.46v6.87h-2.49v-10.3h3.07l2.31 5.15 2.3-5.15h3.07z" />
            </>
          )}
          <path d="m13.1 11.6c-4.93 0.0278-9.69 0.602-13 2.01l1.17 2.76c2.6-1.1 7.17-1.75 11.9-1.77 4.68-0.0264 9.54 0.536 12.8 1.54l0.877-2.87c-3.75-1.15-8.78-1.7-13.7-1.67z" />
        </g>
        <g fill={TEXT}>
          {showLabel && (
            <>
              <path d="m124.6 3.06-2.76 10.3h-2.93l-1.84-6.68-1.79 6.68h-2.93l-2.76-10.3h2.76l1.58 7.06 1.89-7.06h2.64l1.8 7.06 1.65-7.06z" />
              <path d="m108.4 8.167c0.0522 1.78-0.715 3.71-2.32 4.62-1.51 0.868-3.39 0.899-5.04 0.42-1.57-0.519-2.78-1.94-3.07-3.56-0.303-1.46-0.18-3.04 0.504-4.37 0.735-1.35 2.17-2.26 3.69-2.38 1.73-0.237 3.68 0.13 4.9 1.46 0.956 1.02 1.36 2.44 1.33 3.82zm-3.51 2.6c0.883-1.13 0.873-2.68 0.597-4.02-0.241-1.02-1.09-1.95-2.19-1.99-1.06-0.101-2.15 0.595-2.44 1.64-0.437 1.23-0.4 2.64 0.114 3.85 0.424 0.88 1.41 1.46 2.38 1.32 0.585-0.0568 1.16-0.326 1.53-0.795z" />
              <path d="m95.8 13.3h-2.55l-4.35-7.04v7.04h-2.43v-10.3h3.16l3.74 5.88v-5.88h2.43z" />
              <path d="m85.2 13.3h-3.25l-3.07-4.11-0.62 0.751v3.36h-2.65v-10.3h2.65v4.64l3.67-4.64h3.07l-4.01 4.75z" />
            </>
          )}
          <path d="m2.71 4.242c-1.24 2.58-1.23 6.01-0.0506 10.3l2.89-0.801c-1.06-3.84-0.904-6.56-0.137-8.15 0.757-1.66 1.9-2.32 4.51-2.42 0.787-1.11 1.65-1.92 3.02-2.55-1.196-0.3477-2.039-0.5398-3.784-0.4781-2.131 0.05-5.208 1.519-6.448 4.099zm7.61-0.807c-0.76 1.04-1.18 2.17-1.21 3.67 0 0.75 0.13 1.86 0.721 3.04s1.93 2.46 3.78 2.46 3.19-1.27 3.78-2.46c0.591-1.18 0.721-2.29 0.721-3.04 0-1.39-0.522-2.71-1.34-3.88 2.48-0.653 4.41 0.24 5.1 1.98 0.668 1.41 0.769 4.19-0.223 8.56l2.93 0.664c1.05-4.65 1.2-7.99 0.0078-10.5-1.19-2.52-3.82-3.68-6.9-3.82-1.45-0.0144-2.46-9.66e-4 -3.748 0.5195s-2.678 1.516-3.618 2.806zm4.787 3.66c0.067 0.58-0.08 1.22-0.404 1.71-0.25 0.375-0.645 0.795-1.1 0.795-0.451-5.1e-5 -0.845-0.419-1.1-0.795-0.324-0.486-0.476-1.13-0.404-1.71 0.137-1.1 0.4815-2.124 1.471-3.083 0.9228 0.9167 1.407 1.993 1.537 3.083z" />
          <path d="m20.5 5.7c-0.546 0-1 0.454-1 1s0.454 1 1 1 1-0.454 1-1-0.454-1-1-1z" />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
