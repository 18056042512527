import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as areaReducer } from './area/area.slice';
import { areasPersistConfig, AreasTableSlice, reducer as areasReducer } from './area/areas.slice';
import { reducer as positionReducer } from './position/position.slice';
import {
  positionsPersistConfig,
  PositionsTableSlice,
  reducer as positionsReducer,
} from './position/positions.slice';
import { reducer as userReducer } from './user/user.slice';
import { reducer as usersReducer, userPersistConfig, UserTableSlice } from './user/users.slice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  users: persistReducer<UserTableSlice>(userPersistConfig, usersReducer),
  position: positionReducer,
  positions: persistReducer<PositionsTableSlice>(positionsPersistConfig, positionsReducer),
  area: areaReducer,
  areas: persistReducer<AreasTableSlice>(areasPersistConfig, areasReducer),
});

export { rootPersistConfig, rootReducer };
