const en = {
  common: {
    settings: 'Settings',
    version: 'Version {{version}}',
  },
  settings: {
    mode: 'Mode',
    presets: 'Presets',
    fullscreen: 'Fullscreen',
    exitFullscreen: 'Exit Fullscreen',
  },
  navigation: {
    general: 'General',
    dashboard: 'Dashboard',
    content: 'Content',
    pages: 'Pages',
    posts: 'Posts',
    postCategories: 'Categories',
    accounts: 'Accounts',
    users: 'Users',
    permissions: 'Permissions',
    settings: 'Settings',
  },
  errors: {
    404: {
      title: '404 Page Not Found',
      header: 'Sorry, page not found!',
      body: "Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling.",
    },
  },
};

export default en;
