import storage from 'redux-persist/lib/storage';

import { User } from '../../@types/user';
import createTableSlice, { TableFilters, TableSliceState } from '../utils/createTableSlice';

export type UserTableFilters = TableFilters<{ type: string }>;

export const userPersistConfig = {
  key: 'users',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['rowsPerPage', 'orderBy', 'order'],
};

export const slice = createTableSlice<User, UserTableFilters>({
  name: 'users',
  initialState: {
    filters: {
      type: 'all',
    },
  },
});

export type UserTableSlice = TableSliceState<User, UserTableFilters>;

export const { actions, reducer } = slice;

export default slice;
