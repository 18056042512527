import { createContext, ReactNode } from 'react';

import { ThemeColorPresets, ThemeMode } from '../components/settings/type';
import { defaultSettings } from '../config';
import useLocalStorage from '../hooks/useLocalStorage';
import getColorPresets, { colorPresets, defaultPreset } from '../utils/getColorPresets';

type ColorVariants = {
  name: string;
  lighter: string;
  light: string;
  main: string;
  dark: string;
  darker: string;
  contrastText: string;
};

type SettingsContextProps = {
  themeMode: ThemeMode;
  themeColorPresets: ThemeColorPresets;
  setColor: ColorVariants;
  colorOption: {
    name: string;
    value: string;
  }[];

  onToggleMode: VoidFunction;
  // onChangeMode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeMode: (themeMode: ThemeMode) => void;
  onChangeColor: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onResetSetting: VoidFunction;
};

const initialState: SettingsContextProps = {
  ...defaultSettings,

  onToggleMode: () => {},
  onChangeMode: () => {},

  onChangeColor: () => {},
  setColor: defaultPreset,
  colorOption: [],

  onResetSetting: () => {},
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeColorPresets: initialState.themeColorPresets,
  });

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  };

  // const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSettings({
  //     ...settings,
  //     themeMode: (event.target as HTMLInputElement).value as ThemeMode,
  //   });
  // };

  const onChangeMode = (themeMode: ThemeMode) => {
    // const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, themeMode });
  };

  const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeColorPresets: (event.target as HTMLInputElement).value as ThemeColorPresets,
    });
  };

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeColorPresets: initialState.themeColorPresets,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,

        onToggleMode,
        onChangeMode,

        onChangeColor,
        setColor: getColorPresets(settings.themeColorPresets),
        colorOption: colorPresets.map((color) => ({
          name: color.name,
          value: color.main,
        })),

        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsContext, SettingsProvider };
