import { call, put, select, takeEvery } from 'redux-saga/effects';

import { Area } from '../../@types/area';
import axios from '../../utils/axios';
import { RootState } from '../store';
import { actions } from './areas.slice';

function* fetchList() {
  try {
    yield put(actions.startLoading());
    const { page, rowsPerPage, filters, order, orderBy } = yield select(
      (state: RootState) => state.areas
    );
    const params = {
      ...filters,
      page,
      perPage: rowsPerPage,
      sort: `${order === 'desc' ? '-' : ''}${orderBy}`,
    };
    const response: { data: { data: Area[]; total: number } } = yield call(axios.get, '/v1/areas', {
      params,
    });
    yield put(actions.setData(response.data));
  } catch (error) {
    yield put(actions.setError(error));
  }
}

export default function* areasSaga() {
  yield takeEvery(actions.loadData.type, fetchList);
  yield takeEvery(actions.setPage.type, fetchList);
  yield takeEvery(actions.setRowsPerPage.type, fetchList);
  yield takeEvery(actions.setFilters.type, fetchList);
  yield takeEvery(actions.setTab.type, fetchList);
  yield takeEvery(actions.setSortBy.type, fetchList);
}
