import storage from 'redux-persist/lib/storage';

import { Position } from '../../@types/position';
import createTableSlice, { TableFilters, TableSliceState } from '../utils/createTableSlice';

export type PositionTableFilters = TableFilters;

export const positionsPersistConfig = {
  key: 'positions',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['rowsPerPage', 'orderBy', 'order'],
};

export const slice = createTableSlice<Position, PositionTableFilters>({
  name: 'positions',
  initialState: {},
});

export type PositionsTableSlice = TableSliceState<Position, PositionTableFilters>;

export const { actions, reducer } = slice;

export default slice;
