import { enUS, ukUA } from '@mui/material/locale';

import { SettingsValueProps } from './components/settings/type';

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const DEFAULT_DEBOUNCE_TIME = 500;

const MOBILE_HEIGHT = 64;
// const MAIN_DESKTOP_HEIGHT = 88;
const DASHBOARD_DESKTOP_HEIGHT = 72;

export const HEADER = {
  MOBILE_HEIGHT,
  // MAIN_DESKTOP_HEIGHT,
  DASHBOARD_DESKTOP_HEIGHT,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: DASHBOARD_DESKTOP_HEIGHT - 12,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeColorPresets: 'default',
};

// LANGUAGES
// Please remove `localStorage` when you change settings.

export const languages = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/flag_en.svg',
  },
  {
    label: 'Українська',
    value: 'uk',
    systemValue: ukUA,
    icon: '/assets/icons/flags/flag_uk.svg',
  },
];

export const defaultLanguage = languages[0];
