import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { User } from '../../@types/user';
import axios from '../../utils/axios';
import { actions } from './user.slice';

function* fetchUser(action: PayloadAction<{ id: number }>) {
  try {
    yield put(actions.startLoading());

    const response: { data: User } = yield call(axios.get, `/v1/users/${action.payload.id}`);
    yield put(actions.setData(response.data));
  } catch (error) {
    yield put(actions.setError(error));
  }
}

export default function* userSaga() {
  yield takeEvery(actions.loadData.type, fetchUser);
}
