import { createSlice, DeepPartial, Draft } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { merge } from 'lodash';

export type DetailsSliceState<T> = {
  // id: number | null;
  data: T;
  isLoading: boolean;
  error: Error | string | null;
};

const DEFAULT_STATE = {
  // id: null,
  data: null,
  error: null,
  isLoading: false,
};

interface CreateSliceParams<T> {
  name: string;
  initialState?: DeepPartial<DetailsSliceState<T>>;
  // reducers?: any;
  // extraReducers?: any;
}

function createDetailsSlice<T>({
  name,
  initialState: state,
}: // reducers,
// extraReducers,
CreateSliceParams<T>) {
  const initialState = merge({}, DEFAULT_STATE, state) as DetailsSliceState<T>;

  return createSlice({
    name,
    initialState,
    reducers: {
      reset: () => ({ ...initialState }),

      startLoading(state) {
        state.isLoading = true;
      },

      setError(state, action: PayloadAction<Error | string | null>) {
        state.isLoading = false;
        state.error = action.payload;
      },

      setData(state, action: PayloadAction<Draft<T>>) {
        state.isLoading = false;
        state.data = action.payload;
      },

      loadData(state, action: PayloadAction<{ id: number }>) {
        // state.id = action.payload;
      },

      // ...reducers,
    },
    // extraReducers: {
    //   ...extraReducers,
    // },
  });
}

export type CreateDetailsSlice<T> = ReturnType<typeof createDetailsSlice<T>>;

export default createDetailsSlice;
