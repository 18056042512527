import { Area } from '../../@types/area';
import createDetailsSlice, { DetailsSliceState } from '../utils/createDetailsSlice';

export const areaSlice = createDetailsSlice<Area>({
  name: 'area',
  initialState: {},
});

export type AreaDetailsSlice = DetailsSliceState<Area>;

export const { actions, reducer } = areaSlice;

export default areaSlice;
