import { call, put, select, takeEvery } from 'redux-saga/effects';

import { Position } from '../../@types/position';
import axios from '../../utils/axios';
import { RootState } from '../store';
import { actions } from './positions.slice';

function* fetchPositions() {
  try {
    yield put(actions.startLoading());
    const { page, rowsPerPage, filters, order, orderBy } = yield select(
      (state: RootState) => state.positions
    );
    const params = {
      ...filters,
      page,
      perPage: rowsPerPage,
      sort: `${order === 'desc' ? '-' : ''}${orderBy}`,
    };
    const response: { data: { data: Position[]; total: number } } = yield call(
      axios.get,
      '/v1/positions',
      {
        params,
      }
    );
    yield put(actions.setData(response.data));
  } catch (error) {
    yield put(actions.setError(error));
  }
}

export default function* positionsSaga() {
  yield takeEvery(actions.loadData.type, fetchPositions);
  yield takeEvery(actions.setPage.type, fetchPositions);
  yield takeEvery(actions.setRowsPerPage.type, fetchPositions);
  yield takeEvery(actions.setFilters.type, fetchPositions);
  yield takeEvery(actions.setTab.type, fetchPositions);
  yield takeEvery(actions.setSortBy.type, fetchPositions);
}
