import storage from 'redux-persist/lib/storage';

import { Area } from '../../@types/area';
import createTableSlice, { TableFilters, TableSliceState } from '../utils/createTableSlice';

export type AreaTableFilters = TableFilters<{ parentId: number }>;

export const areasPersistConfig = {
  key: 'areas',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['rowsPerPage', 'orderBy', 'order'],
};

export const slice = createTableSlice<Area, AreaTableFilters>({
  name: 'areas',
  initialState: {},
});

export type AreasTableSlice = TableSliceState<Area, AreaTableFilters>;

export const { actions, reducer } = slice;

export default slice;
